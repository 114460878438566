import React from 'react'
import Container from 'gatsby-theme-amsterdam/src/components/Container'
import SEO from 'gatsby-theme-amsterdam/src/components/SEO'
import styled from '@emotion/styled'

const Title = styled.h1`
  font-weight: ${props => props.theme.fonts.boldWeight};
  line-height: 1.25;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 1rem;
  font-size: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.5rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  p {
    line-height: 1.5;
    margin: 0 0 1.75rem;
  }
  a {
    transition: 0.3s color;
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: ${props => props.theme.colors.secondary} !important;
    }
  }
  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 0.9rem;
    padding: 0.25rem;
    background: ${props => props.theme.colors.code};
    color: ${props => props.theme.colors.text};
    border-radius: 0.3em;
  }
`

const AboutPage = ({ data }) => {
  return (
    <Container>
      <SEO title="About" description="This is just an example page" />
      <Title>About</Title>
      <Content>
        <p>Hello! My name is Jame coyne.</p>
        <br />
        <p>
          I’m a product focused full stack software engineer working primarily in frontend
          web development.
        </p>
        <p>
          Currently I am a contractor at{' '}
          <a href="https://www.meta.com">Meta</a> through <a href="https://method.dev/">Method Development</a>{' '}
          Working to develop internal tooling to support infrastructure needs.
          I am proficient at React, graphql, node, PHP, python, and many others.
        </p>
        <p>
          I have also worked in the capacity of a creative technologist with
          <a href="https://www.masarystudios.com/"> Masary Studios</a> where I
          primarily assisted{' '} in building
          large scale interactive installations, such as{' '}
          <a href="https://www.masarystudios.com/projects#/saywhatyouwill/">
            "Say What You Will"
          </a>{' '}
          and{' '}
          <a href="https://www.masarystudios.com/projects#/memory-diffusion-1/">
            Memory Diffusion
          </a>
          .
        </p>
        <p>
          Prior to this, I have worked at{' '}
          <a href="https://blackmath.com/">Black Math</a> where I worked as a
          creative technologist - primarily developing features for their
          existing product, Kineticards, as well as contributing technical
          direction to client work, and building IT and digital asset management
          infrastructure to support the existing creative operations
        </p>
        <p>
          I’m currently based in the Boston area, and I have a degree in a
          computer science from Wentworth Institute of Technology.
          {/* You're welcome to read my resume, but you'll find most of the same content on
          this page.  */}
          Feel free to contact me by email at{' '}
          <a href="mailto:jamecoyne@protonmail.com">jamecoyne@protonmail.com</a>
        </p>
      </Content>
    </Container>
  )
}

export default AboutPage
